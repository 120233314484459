export const Banner = {};
export const FeatureInfo = {};
export const Feature = {
  title: "Explore The Services We Offer For You",
  des:
    "Nunc sodales lobortis arcu, sit amet venenatis erat placerat a. Donec lacinia magna nulla, cursus impediet augue egestas id. Suspendisse dolor lectus, pellentesque quis tincidunt ac, dictum id neque.",
  card: [
    {
      title: "Web design",
      des: "Nullam quis libero in lorem accumsan sodales. Nam vel nisi eget."
    },
    {
      title: "Marketing",
      des: "Nullam quis libero in lorem accumsan sodales. Nam vel nisi eget."
    },
    {
      title: "SEO",
      des: "Nullam quis libero in lorem accumsan sodales. Nam vel nisi eget."
    },
    {
      title: "Graphics Design",
      des: "Nullam quis libero in lorem accumsan sodales. Nam vel nisi eget."
    }
  ]
};
export const Count = {};
export const About = {};
export const FeatureList = {};
export const News = {};
export const Contact = {};
