export default {
  Header: ["服務項目", "安全性", "優點", "產品服務", "聯絡我們"],
  Banner: {
    title: "我們有最好的人工智能解決方案",
    des: "樂宇是一家以研發為核心的公司，專注在流程自動化(RPA) ，使用機器人自動執行重複性任務與端對端業務流程，以提高生產力、消除錯誤和節省成本",
  },
  Feature: {
    title: "我們可以服務您的項目",
    des: "樂宇是一家以研發和秉持以「人」為本為核心的公司， 專注在各式系統的開發且注重使用者體驗，我們能協助企業轉型、導入人工智慧和混合雲彈性配置。配置GPU/TPU達到運算性能最佳化和低延遲。即時數據的可視化，使企業能輕鬆檢視營運效率。即時通知系統，能做到即時告知企業有問題的營運環節，避免災害擴大。",
    card: [
      {
        title: "即時通知",
        des: "多元管道即時通知，重要訊息不漏接(line、mail、telegram等)",
      },
      {
        title: "版本快速無痛部署",
        des: "系統更新時，自動化大規模更新至各個裝置",
      },
      { title: "人工智慧", des: "協助企業導入人工智慧，節省企業成本" },
      {
        title: "混合雲彈性配置",
        des: "彈性配置企業最適合的系統架構，比如：邊緣計算、雲端計算、地端計算",
      },
    ],
  },
  FeatureList: {
    title: "探索我們提供給你的服務",
    des: "Nunc sodales lobortis arcu, sit amet venenatis erat placerat a. Donec lacinia magna nulla, cursus impediet augue egestas id. Suspendisse dolor lectus, pellentesque quis tincidunt ac, dictum id neque.",
    card: [
      {
        title: "高品質",
        des: "高品質的自動化，不必犧牲服務可靠性就能翻新舊版應用程式，打造生命週期更迅速且無營運負擔的雲端原生應用程",
      },
      {
        title: "分析",
        des: "我們擁有專業的資料專家，將您資料轉化為可行深入分析資料，為你訓練出屬於自己的人工智慧，掌握市場新契機",
      },
      {
        title: "實惠",
        des: "依企業需求，我們結合了各種技術，為行動應用程式、熱門訊息傳遞平台和 IoT 裝置打造對話式介面，為您打造出屬於您最經濟實惠的系統架構",
      },
      {
        title: "使用簡單",
        des: "更貼近使用者，提升企業生產力和協作能力的大好機會，讓員工輕鬆完成工作且事半功倍",
      },
      {
        title: "免費諮詢",
        des: "無論是與高階主管一同規劃策略，還是工程師對工程師的支援服務，樂宇諮詢顧問和客戶技術工程師都可配合您的需求，協助您制定策略並幫助您達成目標",
      },
      {
        title: "有效兼容",
        des: "舊版系統複雜且兼容困難，我們會即時進行整合。我們不希望客戶體驗受限於特定通路",
      },
    ],
  },
  Security: {
    title: "資料安全有保障",
    card: [
      { title: "連線安全", des: "ssl加密傳輸" },
      { title: "資料儲存", des: "重要資料加密" },
      { title: "資料控管", des: "嚴格控管權限" },
      { title: "駭客攻擊防護", des: "防火牆、L7網路安全防護" },
      { title: "主機機房", des: "安全快速穩定" },
      { title: "異地備援", des: "資料即時異地備援" },
    ],
  },
  Count: {
    numbers: [
      { num: 5, des: "活動項目" },
      { num: 40, des: "企業用戶" },
      { num: 20, des: "完成項目" },
      { num: 600, des: "系統使用數" },
    ],
  },
  News: {
    title: "產品/服務",
    cards: [
      {
        title: "客製化網站設計",
        des: "客製化規格、用多少算多少、搜尋引擎最佳化、Ai巨量資料分析、高流量、保證網站高可用",
        url: "http://leyutechs.com/doc/web.pdf",
      },
      {
        title: "智慧監控",
        des: "24小時安全警衛，人物特徵學習及判斷，警報通知系統(line、mail、telegram)，問題影像留存以利分析",
        desList: [
          "人臉辨識",
          "電子圍籬",
          "水位分析",
          "口罩辨識",
          "依需求提供多種AI辨識",
        ],
        url: "http://leyutechs.com/doc/web.pdf",
      },
      {
        title: "Ai數據搜集",
        des: "對機台擷取螢幕訊息並轉成可編輯且可搜尋的資料。彈性混合雲配置，用多少算多少。同時監控多台設備，大幅度增加生產效率。",
        url: "http://leyutechs.com/doc/web.pdf",
      },
      {
        title: "系統串接整合",
        des: "對客戶既有服務做軟體串接，整合所有服務於一個控制平台，做到規模化管理",
        desList: ["手機", "網頁", "Line", "無人機"],
        url: "http://leyutechs.com/doc/web.pdf",
      },
    ],
  },
  thx: "謝謝您的來信",
};
