export default {
  Header: ["SERVICES", "SECURITY", "ADVANTAGE", "PRODUCT", "CONTACT"],
  Banner: {
    title: "We have the best artificial intelligence solutions",
    des: "LEYU is an R&D-focused company, specializing in process automation (RPA), using robots to automate repetitive tasks and end-to-end business processes to increase productivity, eliminate errors and save costs",
  },
  Feature: {
    title: "Explore The Service We Offer for you",
    des: "We specialize in the development of various systems with a strong focus on user experience. We can help enterprises transform, introduce artificial intelligence, and configure hybrid clouds flexibly. Configure GPU/TPU to achieve optimal computing performance and low latency. Real-time data visualization enables enterprises to easily review operational efficiency. Real-time notification system to inform enterprises of problematic operational areas in real time to prevent disaster expansion.",
    card: [
      {
        title: "Notification",
        des: "Multi-channel real-time notification, important messages are not missed (line, mail, telegram, etc.)",
      },
      {
        title: "Rapid and painless deployment of versions",
        des: "Automatic mass update to each device during system update",
      },
      {
        title: "Artificial Intelligence",
        des: "Helping companies implement artificial intelligence to save business costs",
      },
      {
        title: "Hybrid Cloud Flexible Configuration",
        des: "Flexible configuration of the most suitable system architecture for enterprises, such as: edge computing, cloud computing, ground computing",
      },
    ],
  },
  FeatureList: {
    title: "Explore The Services We Offer For You",
    des: "Nunc sodales lobortis arcu, sit amet venenatis erat placerat a. Donec lacinia magna nulla, cursus impediet augue egestas id. Suspendisse dolor lectus, pellentesque quis tincidunt ac, dictum id neque.",
    card: [
      {
        title: "High Quality Automation",
        des: "High-quality automation to refurbish legacy applications without sacrificing service reliability, creating cloud-native applications with faster lifecycles and no operational burden",
      },
      {
        title: "Analytics",
        des: "We have professional data experts to turn your data into feasible in-depth analysis data, so that you can train your own artificial intelligence to grasp new market opportunities",
      },
      {
        title: "affordable pricing",
        des: "Depending on the needs of your business, we combine technologies to create conversational interfaces for mobile applications, hot messaging platforms and IoT devices to create the most cost effective system architecture for you",
      },
      {
        title: "easy to use",
        des: "This is a great opportunity to get closer to the users and improve the productivity and collaboration of the company, so that employees can do their jobs easily.",
      },
      {
        title: "Free support",
        des: "Whether it has planning a strategy with a senior executive or engineer-to-engineer support services, Leyu consultants and customer technical engineers can work with your needs to help you develop a strategy and help you achieve your goals",
      },
      {
        title: "Web design",
        des: "The old system was complex and difficult to integrate, and we will do so immediately. We do not want our customers experience to be limited to specific channels.",
      },
    ],
  },
  Security: {
    title: "Data security is guaranteed",
    card: [
      { title: "Connected Security", des: "ssl encrypted transmission" },
      { title: "Data Storage", des: "Encryption of important information" },
      {
        title: "Data security is guaranteed",
        des: "Strict control of authority",
      },
      {
        title: "Hacker Attack Protection",
        des: "Firewall, L7 network security protection",
      },
      { title: "Main Machine Location", des: "Safe and fast stabilization" },
      { title: "Offsite Backup", des: "Real-time off-site backup of data" },
    ],
  },
  Count: {
    numbers: [
      { num: 5, des: "Active Projects" },
      { num: 40, des: "Number of Entrepreneurs" },
      { num: 20, des: "Computed Projects" },
      { num: 600, des: "Number of users in the system" },
    ],
  },
  News: {
    title: "Product/Service",
    cards: [
      {
        title: "Customized website design",
        des: "Customized specifications, pay as you call, search engine optimization, Ai huge amount of data analysis, high traffic, ensure high availability of the site",
        url: "http://leyutechs.com/doc/web.pdf",
      },
      {
        title: "Intelligent Monitoring",
        des: "24-hour security guard, character feature learning and judgment, alarm notification system (line, mail, telegram), automatic retention of problem images for analysis",
        desList: [
          "Face Recognition",
          "Electronic Fencing",
          "Water Level Analysis",
          "Mask Recognition",
          "Multiple AI recognition on demand",
        ],
        url: "http://leyutechs.com/doc/web.pdf",
      },
      {
        title: "Ai Data Collection",
        des: "Capture on-screen messages from the machine and turn them into editable and searchable data. Flexible hybrid cloud configuration, use as much or as little as you want. Monitor multiple devices simultaneously to dramatically increase productivity.",
        url: "http://leyutechs.com/doc/web.pdf",
      },
      {
        title: "System integration",
        des: "Software connectivity to existing services of customers, integration of all services in one control platform to achieve scale management.",
        desList: ["Cell phone", "Website", "Line", "Drones"],
        url: "http://leyutechs.com/doc/web.pdf",
      },
    ],
  },
  thx: "Thank you for your reply.",
};
